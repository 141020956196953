.App1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* height: 100vh; */
    width: 100%;
    padding-left: 6%;
    flex-direction: column;
}
.list-container {
    display: flex;
    font-size: 18px;
    background-color: #eee;
    flex-direction: column;
}
.item-container {
    background-color: #fff;
    border: 1px solid black;
    padding: 25px 70px;
    margin: 15px 50px;
}

.drag {
    margin-bottom: -2%;
}

.completedListDescription {
    text-decoration: line-through;
}

.lineStepList {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subLineStepList {
    display: flex;
    align-items: center;
}

.line {
    display: flex;
    justify-content: space-between;
}

.deleteIcon {
    display: flex;
    align-items: center;
    margin-right: 10%;
}

.addNewStep {
    margin: 5% 5% 5% -4%;
}