@import "scss/colors";
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&family=Montserrat:wght@300&display=swap');

.App {
    text-align: center;
}

.App-header {
    height: 4rem;
    min-height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0d498f;
}

.App-link {
    color: rgb(112, 76, 182);
}

.MuiDialog-paperWidthSm {
    min-width: 400px;
}
